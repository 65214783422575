import * as React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import {
    wrapper__row,
    contactIcon,
    socialIcon,
    facebook,
    linkedin,
    grid,
    contactModule,
    contactModule__icon,
    iconWithText,
    socialIconGroup
} from "../../scss-modules/contact-us.module.scss";

//Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileAlt, faUsers, faTruckLoading, faMapMarkerAlt, faPhone} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin, faFacebookSquare} from '@fortawesome/free-brands-svg-icons';

const bannerImg = '../../banners/contact-us_banner.jpg';


//RENDER
export default function ContactUsPage() {
    return (
        <Layout pageTitle={'Contact us'}>
            <SplashBanner
                title={"Contact Us"}
                content={"Contractor & Client 24/7 support."}
                backgroundImg={bannerImg}
            />

            <section>
                <div className="container">

                    <div className="split-content">
                        <div className="module text">
                            <h3>Contact information</h3>
                            <div className={wrapper__row}>
                                <div className={iconWithText}>
                                    <img src={'../../icons/font-awesome/phone.svg'} className={contactIcon}/>
                                    <p><a
                                        href="tel:+44 2077130569">+44 207 7130 569</a> (HQ
                                        Phone)</p>
                                </div>
                                <div className={iconWithText}>
                                    <img src={'../../icons/font-awesome/map-pin.svg'} className={contactIcon}/>
                                    <p> Performance
                                        Energy
                                        Ltd, United Kingdom
                                    </p>
                                </div>
                                < div className={socialIconGroup}>
                                    <a href="https://www.facebook.com/PerformanceEnergyltd"
                                       target="_blank" rel="noopener noreferrer">
                                        <img src={'../../icons/font-awesome/facebook.svg'}
                                             className={`${contactIcon} ${socialIcon}`}/>
                                    </a>
                                    <a href="https://www.linkedin.com/company/performance-energy/?viewAsMember=true"
                                       target="_blank" rel="noopener noreferrer">
                                        <img src={'../../icons/font-awesome/linkedin.svg'}
                                             className={`${contactIcon} ${socialIcon}`}/></a>
                                </div>
                            </div>

                        </div>
                        <div className="module media small">
                            <img
                                src={'../../article/contact-us_article.jpg'}
                                alt="Contact us today"/>
                        </div>
                    </div>


                    <div className={`${grid} grid`}>
                        <div className={`${contactModule} grid__column`}>
                            <img src={'../../icons/font-awesome/register-profile.svg'} className={contactModule__icon}/>
                            <h4>Submit your CV</h4>
                            <p>View our live vacancies or to submit your profile for review.</p>
                            <Link to={'/jobs'} className="button primary arrow-light">
                                <span>View jobs</span>
                            </Link>
                        </div>
                        <div className={`${contactModule} grid__column`}>
                            <img src={'../../icons/font-awesome/personnel-requirement.svg'}
                                 className={contactModule__icon}/>
                            <h4>Client Personnel Requirement?</h4>
                            <p>Contact one of our specialists today with your request.</p>
                            <a href="mailto:client-requirement@performance-energy.com" target="_blank"
                               rel="noopener noreferrer"
                               className="button primary arrow-light">
                                <span>Email us</span>
                            </a>
                        </div>
                        <div className={`${contactModule} grid__column`}>
                            <img src={'../../icons/font-awesome/equipment-required.svg'}
                                 className={contactModule__icon}/>
                            <h4>Equipment Requirement?</h4>
                            <p>Contact one of our equipment specialists today with your request.</p>
                            <a href="mailto:equipment-requirement@performance-energy.com" target="_blank"
                               rel="noopener noreferrer"
                               className="button primary arrow-light">
                                <span>Email us</span>
                            </a>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
}
